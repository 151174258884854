import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import "./index.css";
import "../i18n";
import Footer from "../components/home/Footer";
import Header from "../components/home/Header";
import FAQ from "../components/home/FAQ";
import Features from "../components/home/Features";
import Banner from "../components/home/Banner";
// import Why from "./Why";
import Developers from "../components/home/Developers";
import HowItWorks from "../components/home/HowItWorks";
import UseCases from "../components/home/UseCases";
import PricingModel from "../components/home/PricingModel";
import "@fortawesome/fontawesome-free/css/all.min.css";
import SEO from "../components/common/seo";
import { useTranslation } from "react-i18next";

const Home: React.FunctionComponent<RouteComponentProps<{}>> = () => {
  const { t } = useTranslation();

  return (
    <section className="">
      <SEO
        props={{
          title: t("home.seoTitle"),
        }}
      />
      <Header />
      <Banner />
      {/* <HowItWorks />
      <Features />
      <UseCases /> */}
      {/* <Why /> */}
      {/* <Developers />
      <PricingModel />
      <FAQ /> */}
      {/* <Footer /> */}
    </section>
  );
};
export default Home;
