import React from "react";
import { Trans, useTranslation } from "react-i18next";
// import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";

import heroImage from "./hero.webp";
import securityImg from "../../../assets/security.svg";
import "./style.css";
// import { useUserDetails } from "../../UserDetails/store";

import { ACCOUNTS_HOSTNAME, PAYOUTS_HOSTNAME } from "../../../utils/constants";

const Banner: React.FunctionComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();

  // const { userDetailsState } = useUserDetails();
  // const { data } = userDetailsState.data;
  // const isLoggedIn = Cookies.get("isLoggedIn");

  const navToSignup = (): void => {
    // if (Object.keys(data).length > 0 || isLoggedIn) {
    //   history.push("/dashboard");
    //   return;
    // }

    window.location.href = `${ACCOUNTS_HOSTNAME}/?redirecturi=${PAYOUTS_HOSTNAME}/dashboard`;
  };

  return (
    <section className="bg-white w-full">
      <div className="pt-18 banner-wrapper-1 h-screen">
        <div className="home-image w-full bg-background-platinum pt-12 lg:pb-24 pb-10 flex flex-col justify-center items-center h-full relative">
          <h1 className="heading-2 text-neutral-charcoal text-center  mt-0 md:mt-32 md:w-[58rem] mb-10 md:mb-12 !text-3xl md:!text-6xl px-8 md:!leading-[60px]">
            <Trans i18nKey="home.banner.paragraph">
              Empowering tomorrow's <br /> financial landscape,
              <p className="heading-2 highlight !text-3xl md:!text-6xl md:!leading-[60px]">
                one innovation at a time.
              </p>
            </Trans>
          </h1>
          <a
            href="/about"
            className="flex button-size mb-4 md:mb-6 w-12 content-center flex-wrap justify-center border border-blue-primary rounded button-text text-blue-primary"
          >
            {t("home.banner.actionBtnText")}
          </a>
        </div>
      </div>
    </section>
  );
};
export default Banner;
